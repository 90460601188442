<!--
File: YearsDropdown.vue
Description: show the dropdown combo with the years list that are exist in the RDB.
-->
<template>
  <md-field>
    <label for="year">{{ label }}</label>
    <md-select v-model='year' name="year" id="year" @md-selected='onChange'>
      <md-option v-for='(y, ind) in years' :key='ind' :value='y.survey_year'>
        {{ y.survey_year }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'years-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: Number },
    },

    data() {
      return {
        year: null,
        yearsListLoaded: false
      }
    },

    mounted() {
      this.$store.dispatch('GET_YEARS_LIST').then(() => {
        this.yearsListLoaded = true
        if (this.initial_value && !this.year) this.year = this.initial_value
      })
    },

    methods: {
      onChange() {
        this.$emit('input', this.year)
      },
    },

    computed: {
      years() {
        return this.$store.state.Dropdowns.years;
      }
    },
    
    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.yearsListLoaded) {
          this.year = newValue
        }
      },
    }
  }
</script>